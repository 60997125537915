import './App.css';
import hero from './assets/images/01_Hero/hero.jpg'
import logo from './assets/images/aw-logo.svg'
import backgroundImage from './assets/images/backgroundImage.png'
import technogym from './assets/images/technogym-logo.png'
import ExerciseSlider from './components/exercise_slider';
import EquipmentSlider from './components/equipment_slider';
import bluelogo from './assets/images/blue-logo.svg';
import privacypolicy from './assets/documents/AW-privacypolicy.pdf';
import cookiepolicy from './assets/documents/AW-cookiepolicy.pdf';


const list = [
  {
    title: 'PERSONAL TRAINING'
  },
  {
    title: 'NUTRIZIONE'
  },
  {
    title: 'TRATTAMENTI RIABILITATIVI'
  },
  {
    title: 'CONSULENZA'
  },
  {
    title: 'HOME FITNESS'
  }
]

function App() {
  return (
    <div className='bg-[#252525] font-Haas'>
      <div className="App w-full m-auto">
        <header className='relative z-0 lg:px-[60px] md:px-[45px] sm:px-[30px] px-[18px] bg-no-repeat bg-cover bg-center bg-top text-left !min-h-screen' style={{ backgroundImage: `url(${hero})` }}>
          <div className='flex pt-[30px]'>
            <img src={logo} alt="logo" className='w-[200px] h-[50px]' />
          </div>
          <div style={{ bottom: "20%" }} className='absolute'>
            <h1 className='xl:text-[90px] lg:text-[70px] md:text-[60px] sm:text-[45px] text-[36px] text-[#dbcdb1] lg:leading-[100px] md:leading-[75px] leading-[40px]'>
              SCOPRI
            </h1>
            <h1 className='xl:text-[90px] lg:text-[70px] md:text-[60px] sm:text-[45px] text-[36px] text-[#dbcdb1] lg:leading-[100px] md:leading-[75px] leading-[40px]'>
              UN’ESPERIENZA
            </h1>
            <h1 className='xl:text-[90px] lg:text-[70px] md:text-[60px] sm:text-[45px] text-[36px] text-[#dbcdb1] lg:leading-[100px] md:leading-[75px] leading-[40px]'>
              DI FITNESS SU MISURA
            </h1>
          </div>
          <div style={{ bottom: "10%" }} className='absolute'>
            <h3 className='md:text-[24px] sm:text-[20px] text-[16px] text-[#dbcdb1]'>
              A DUE PASSI DAL CENTRO DI TORINO,
            </h3>
            <h3 className='md:text-[24px] sm:text-[20px] text-[16px] text-[#dbcdb1]'>
              IN GRAN MADRE.
            </h3>
          </div>
        </header>
        <section className='relative bg-[#ece1ce] rounded-t-3xl -mt-[22px] text-left pb-[145px]'>
          <div className='xl:px-[170px] lg:px-[120px] md:px-[80px] sm:px-[50px] px-[20px] text-[#3f3f3e]'>
            <div className='md:pt-[120px] sm:pt-[90px] pt-[60px]'>
              <h5 className='md:text-[18px] sm:text-[16px] text-[12px]'>EXPERIENCE</h5>
            </div>
            <div className='md:pt-[60px] sm:pt-[45px] pt-[30px]'>
              <h2 className='md:text-[45px] sm:text-[30px] text-[24px]'>
                Studiamo percorsi personalizzati di allenamento e benessere, adatti alle esigenze individuali dei nostri clienti, con l’obiettivo di offrire un’esperienza completa in suite o direttamente a casa.
              </h2>
            </div>
          </div>

          {/* Slider */}
          <div className='lg:px-[60px] md:px-[30px] px-[20px] 2xl:w-[1440px] xl:w-[1280px] lg:w-[1024px] md:w-[768px] sm:w-[640px] w-375px'>
            <ExerciseSlider />
          </div>
        </section>
        {/*
        <section className='relative -mt-[25px] rounded-3xl'>
          <img src={backgroundImage} alt="location interior" className='w-full xl:h-full h-[650px] rounded-3xl object-cover' />
        </section>
*/}
        <section className='text-left text-[#3f3f3e] bg-[#b9b0ad] -mt-[25px]'>
          <div className='xl:px-[170px] lg:px-[120px] md:px-[80px] sm:px-[50px] px-[20px] text-[#3f3f3e]'>
            <div className='md:pt-[120px] sm:pt-[90px] pt-[60px]'>
              <h5 className='md:text-[18px] sm:text-[16px] text-[12px]'>SUITE</h5>
            </div>
            <div className='md:pt-[60px] sm:pt-[45px] pt-[30px]'>
              <h2 className='md:text-[45px] sm:text-[30px] text-[24px]'>
                Sperimenta un ambiente unico, dai dettagli ricercati. Uno spazio familiare e accogliente, i in cui sentirsi a casa. Un luogo intimo,  personale e riservato, dove sentirsi bene.
              </h2>
            </div>
          </div>

          {/* slider */}
          <div>
            <EquipmentSlider />
          </div>

          <div className='md:mx-[30px] sm:mx-[15px] mx-[5px] rounded-3xl bg-[#dad6d8] md:mt-[120px] sm:mt-[60px] mt-[60px]'>
            <div className='2xl:px-[150px] xl:px-[125px] lg:px-[100px] md:px-[75px] sm:px-[50px] px-[15px]'>
              <div className='lg:pt-[115px] md:pt-[100px] sm:pt-[75px] pt-[60px]'>
                <h5 className='md:text-[18px] sm:text-[16px] text-[12px]'>SERVIZI</h5>
              </div>

              <div className='md:pt-[60px] sm:pt-[45px] pt-[30px] lg:pb-[115px] md:pb-[90px] sm:pb-[75px] pb-[60px]'>
                <ul className='lg:text-[60px] md:text-[45px] sm:text-[30px] text-[24px]'>
                  {list.map((item, key) => {
                    return (
                      <li key={key} className={`border-t-2 ${key === 4 ? 'border-b-2' : ''} border-[#3f3f3e] pt-[5px] pb-[5px]`}>
                        {item.title}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className='xl:px-[170px] lg:px-[120px] md:px-[80px] sm:px-[50px] px-[20px] text-[#3f3f3e]'>
            <div className='md:pt-[120px] sm:pt-[90px] pt-[60px]'>
              <h5 className='md:text-[18px] sm:text-[16px] text-[12px]'>CONTATTI</h5>
            </div>
            <div className='md:pt-[60px] sm:pt-[45px] pt-[30px]'>
              <h2 className='md:text-[45px] sm:text-[30px] text-[24px]'>
                RICEVIAMO SU APPUNTAMENTO, <br />contattaci per maggiori informazioni.
              </h2>
            </div>
          </div>

          <div className='font-Ballinger xl:px-[170px] lg:px-[120px] md:px-[80px] sm:px-[50px] px-[20px] md:text-[24px] sm:text-[20px] text-[16px] lg:pb-[130px] md:pb-[100px] sm:pb-[75px] pb-[60px]'>
            <div className='lg:pt-[120px] md:pt-[100px] sm:pt-[75px] pt-[60px]'>
              <p>INFO@AWFITNESS.IT</p>
              <p className='sm:pt-4 pt-4'><a href='tel:+393401257055' rel="noopener noreferrer">+39 340 125 70 55</a></p>
            </div>

            <div className='flex sm:flex-row flex-col pt-4 sm:justify-between sm:items-center text-left'>
              <h3><a href='https://maps.app.goo.gl/Fm3GUv4dYJwJJ3Lo7' rel="noopener noreferrer">VIA FRANCESCO LANFRANCHI 1/G<br />10131 TORINO TO</a></h3>
              <h3 className='sm:pt-0 pt-4'><a href='https://www.instagram.com/aw_fitnesssuite/' rel="noopener noreferrer">> INSTAGRAM</a></h3>
            </div>
          </div>
        </section>


        <footer className='sm:flex hidden rounded-t-3xl bg-[#3f3f3e] justify-between -mt-[25px] xl:px-[170px] lg:px-[120px] md:px-[75px] sm:px-[50px] px-[20px] items-center'>
          <div className='text-[12px] text-[#b9b0ad] flex sm:flex-col flex-row h-full text-left pt-[60px]'>
            <a href={cookiepolicy} rel="noopener noreferrer"><span>PRIVACY POLICY</span></a>
            <a href={privacypolicy} rel="noopener noreferrer"><span>COOKIE POLICY</span></a>
          </div>
          <div className='py-[60px]'>
            <span className='text-[18px] text-[#b9b0ad]'>POWERED BY</span>
            <a href='https://www.technogym.com/' rel="noopener noreferrer"><img src={technogym} alt="gym icon" className='pt-[20px]' /></a>
          </div>
          <div className='flex h-full items-center pt-[60px]'>
            <a href='https://www.blue-to.com/' rel="noopener noreferrer"><img src={bluelogo} alt="blue icon" className='pt-[20px] h-10' /></a>
          </div>
        </footer>

        <footer className='font-Ballinger sm:hidden block rounded-t-3xl bg-[#3f3f3e] justify-between -mt-[25px] xl:px-[170px] lg:px-[120px] md:px-[75px] sm:px-[50px] px-[20px] items-center'>
          <div className='flex justify-between text-left'>
            <div className='pt-[30px] pb-[20px]'>
              <span className='text-[12px] text-[#b9b0ad]'>POWERED BY</span>
              <img src={technogym} alt="gym icon" className='pt-[15px]' />
            </div>
            <div className='flex h-full pt-[80px]'>
              <a href='https://www.blue-to.com/' rel="noopener noreferrer"><img src={bluelogo} alt="blue icon" className='pt-[20px] h-10' /></a>
            </div>
          </div>
          <div className='text-[10px] text-[#b9b0ad] flex flex-row justify-between h-full text-left pb-[20px]'>
            <a href={cookiepolicy} rel="noopener noreferrer"><span>PRIVACY POLICY</span></a>
            <a href={privacypolicy} rel="noopener noreferrer"><span>COOKIE POLICY</span></a>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
