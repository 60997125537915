import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import equipment0 from '../assets/images/03_Suite/equipment (0).jpg'
import equipment1 from '../assets/images/03_Suite/equipment (1).jpg'
import equipment2 from '../assets/images/03_Suite/equipment (2).jpg'
import equipment3 from '../assets/images/03_Suite/equipment (3).jpg'
import equipment4 from '../assets/images/03_Suite/equipment (4).jpg'
import equipment5 from '../assets/images/03_Suite/equipment (5).jpg'
import equipment6 from '../assets/images/03_Suite/equipment (6).jpg'
import equipment7 from '../assets/images/03_Suite/equipment (7).jpg'
import equipment8 from '../assets/images/03_Suite/equipment (8).jpg'
import equipment9 from '../assets/images/03_Suite/equipment (9).jpg'

import prev from '../assets/images/arrow-prev.png'
import next from '../assets/images/arrow-next.png'

import '../../src/css/equipment.css'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "../css/equipment.css"

// import required modules
import { Navigation, Pagination } from "swiper/modules";

const sliders =
  [
    {
      image: equipment1
    },
    {
      image: equipment2
    },
    {
      image: equipment3
    },
    {
      image: equipment4
    },
    {
      image: equipment5
    },
    {
      image: equipment6
    },
    {
      image: equipment7
    },
    {
      image: equipment8
    },
    {
      image: equipment9
    },
    {
      image: equipment0
    }
  ]

const handleChangePrev = () => {
  console.log("first")
  // $('#swiper-button-next').onClick()
  const divPrev = document.querySelector('#equipment .swiper-button-prev')
  if (divPrev) {
    divPrev.click()
  }
}

const handleChangeNext = () => {
  console.log("second")
  const divNext = document.querySelector('#equipment .swiper-button-next')

  if (divNext) {
    divNext.click()
  }
}

export default function App() {
  return (
    <div id="equipment" className="pt-[130px] md:px-0 sm:px-[70px] px-[20px]">
      <Swiper
        slidesPerView={2}
        centeredSlides={true}
        spaceBetween={300}
        breakpoints={{
          0: {
            spaceBetween: 1,
            slidesPerView: 1
          },
          640: {
            spaceBetween: 35,
            slidesPerView: 1
          },
          769: { spaceBetween: 300, slidesPerView: 1.6 },
          1024: { spaceBetween: 300, slidesPerView: 1.9 },
          1280: { spaceBetween: 300, slidesPerView: 2.5 },
          1600: { spaceBetween: 300, slidesPerView: 2.7 },
          2000: { spaceBetween: 300, slidesPerView: 2.7 },
          2500: { spaceBetween: 300, slidesPerView: 2.7 },
          4000: { spaceBetween: 300, slidesPerView: 2.9 }
        }}
        navigation={true}
        modules={[Navigation]}
        className=""
        loop={true}
      >
        {sliders.map((slider, key) => (
          <SwiperSlide key={key} className="rounded-3xl md:w-[540px] lg:w-[540px]">
            <img src={slider.image} alt={`equipment image ${key}`} className="md:h-[650px] sm:h-[600px] h-[500px] md:w-[540px] object-cover rounded-3xl" />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="md:hidden flex justify-between pt-[30px]">
        <div>
          <button id="prev" onClick={handleChangePrev}>
            <img className="w-[45px] h-[45px]" src={prev} alt="preview button" />
          </button>
        </div>
        <div>
          <button id="next" onClick={handleChangeNext}>
            <img className="w-[45px] h-[45px]" src={next} alt="next button" />
          </button>
        </div>
      </div>
    </div>
  );
}