import { Swiper, SwiperSlide } from 'swiper/react';
import slider1 from '../assets/images/02_Experience/slider1.jpg'
import slider2 from '../assets/images/02_Experience/slider2.jpg'
import slider3 from '../assets/images/02_Experience/slider3.jpg'
import slider4 from '../assets/images/02_Experience/slider4.jpg'

import '../../src/css/exercise.css'
import prev from '../assets/images/arrow-prev.png'
import next from '../assets/images/arrow-next.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

const title = [
  {
    name: 'PERSONAL TRAINING',
  },
  {
    name: 'NUTRIZIONE',
  },
  {
    name: 'CURA',
  },
  {
    name: 'INTIMITÀ',
  },
]

const sliders = [
  {
    background: slider1,
    subTitle: 'PERSONAL TRAINING',
    text: `Rafforza il tuo corpo.\nUn team di personal trainer, altamente qualificato, ti accompagnerà nello studio del percorso di allenamento più adatto a te con l’obiettivo di farti sentire bene, in suite o direttamente a casa tua.`
  },
  {
    background: slider2,
    subTitle: 'NUTRIZIONE',
    text: `Siamo quello che mangiamo.\nAmare il proprio corpo e prendersi cura di esso è un esercizio costante. Ad un allenamento fisico su misura abbiniamo un piano alimentare sano ed equilibrato, pensato esclusivamente per te, per un perfetto equilibrio.`
  },
  {
    background: slider3,
    subTitle: 'CURA',
    text: `Concediti un momento per te.\nIl tuo corpo è prezioso ed è importante proteggerlo sempre. Per un’esperienza completa offriamo trattamenti di prevenzione, cura e riabilitazione.`
  },
  {
    background: slider4,
    subTitle: 'INTIMITÀ',
    text: `Prenditi il tuo tempo.\nRilassati, bevi un caffè o una bevanda fresca, vivi la nostra suite. Uno spazio familiare e accogliente, in cui sentirsi a casa. Un luogo intimo, personale e riservato, dove sentirsi bene.`
  },
]

const handleChangePrev = () => {
  console.log("first")
  // $('#swiper-button-next').onClick()
  const divPrev = document.querySelector('#exercise .swiper-button-prev')
  if (divPrev) {
    divPrev.click()
  }
}

const handleChangeNext = () => {
  console.log("second")
  const divNext = document.querySelector('#exercise .swiper-button-next')

  if (divNext) {
    divNext.click()
  }
}

export default function App() {
  return (
    <div id='exercise'>
      <Swiper navigation={true} modules={[Navigation]} spaceBetween={50} className="mySwiper !bg-[#ece1ce] rounded-3xl mt-[120px] !w-full">
        {sliders.map((slider, key) => {
          return (
            <SwiperSlide className='!flex md:!flex-row !flex-col' key={key}>
              <div className='!flex !justify-center !m-auto'>
                <img src={slider.background} alt="slider image 1" className="md:!h-[650px] sm:!h-[600px] !h-[500px] md:!w-[540px] object-cover rounded-3xl" />
              </div>
              <div className='xl:pl-[140px] lg:pl-[100px] md:pl-[75px]sm:pl-[50px]'>
                <div className='pt-[30px]'>
                  <ul className='md:block hidden font-Ballinger'>
                    {title.map((item, key) => {
                      return (
                        <li className={`${slider.subTitle === item.name ? 'text-[#3f3f3e]' : 'text-[#dbcdb1]'}`} key={key}>{item.name}</li>
                      )
                    })}
                  </ul>
                  <span className='md:hidden block font-Ballinger'>{slider.subTitle}</span>
                </div>
                <div className='pt-[15px] xl:w-[500px] lg:w-[400px] md:w-[300px] w-full'>
                  <p className='whitespace-pre-wrap break-words sm:text-[16px] md:text-[24px] lg:text-[24px] text-[#3f3f3e]'>{slider.text}</p>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="flex justify-between pt-[30px] md:hidden">
        <div>
          <button id="prev" onClick={handleChangePrev}>
            <img className="w-[45px] h-[45px]" src={prev} alt="preview button" />
          </button>
        </div>
        <div>
          <button id="next" onClick={handleChangeNext}>
            <img className="w-[45px] h-[45px]" src={next} alt="next button" />
          </button>
        </div>
      </div>
    </div>
  );
}
